import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import Logo from '../layout/partials/Logo';
import HeaderImage from "./../../assets/images/header1.png";


const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm" style={{backgroundimg:'../../header1.png'}}>
        <div className={innerClasses}>
          <div style={{ backgroundImage: 'url(HeaderImage)'}} className="hero-content">
            <Logo style={{display: 'flex',  justifyContent:'center', alignItems:'center'  } } width={350}
                height={350}/>
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="100">
              ISHIKA ENGINEERING WORKS 
            </h1>
            <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
              <strong> Specialist in Feed Mill Spare Part and Machineries  </strong>
                </p>
            <div className="container-xs">
              {/* <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
              Budigre Road, Ward No.22 
                Devanahalli Town, <br/> Bangalore Rural - 562110 
                </p> */}

              <div className="reveal-from-bottom" data-reveal-delay="600">

                {/* <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="https://cruip.com/">
                    Get started
                    </Button>
                  <Button tag="a" color="dark" wideMobile href="https://github.com/cruip/open-react-template/">
                    View on Github
                    </Button>
                </ButtonGroup> */}
               <p className="text-xxs text-color-primary fw-600 tt-u mb-8" >Contact No : <a href="tel:9886716220" style={{color:  "#061e31"}}>+91-9886716220</a> / <a href="tel:7019221242" style={{color:  "#061e31"}}>7019221242</a> </p>
                <p className="text-xxs text-color-primary fw-600 tt-u mb-8" >Mail ID : <a href="mailto: ishikaengworks@gmail.com" style={{color:  "#061e31"}}>ishikaengworks@gmail.com</a></p>
                <button style={{ backgroundColor : "#061e31", 
                            border: "none",
            color: "white",
            fontSize:"15px",
            padding: "10px",
            borderRadius:"20px",
            textAlign: "center"}}
              >
                <a href="mailto: ishikaengworks@gmail.com" style={{color:  "white"}}>Contact Us</a>
                </button>
              </div>
            </div>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
        
              <Image
                className="has-shadow"
                src={require('./../../assets/images/header1.png')}
                alt="Hero"
                width={2600}
                height={504} />
            
          </div>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;