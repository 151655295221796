import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';



const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Customer testimonials'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
     
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="100">
        <Image
          className="has-shadow"
          src={require('./../../assets/images/customer.png')}
          alt="Hero"
          width={800}
          height={504} />
          </div>

              {/* <div className="tiles-item">
              <div className="tiles-item-inner">             
                  <h4 className="text-color-high">
                  Godrej Agrovet PVT LTD [ At Tumkur, Chitradurga ] 
                      </h4>
              </div>
              </div>
              <div className="tiles-item">
              <div className="tiles-item-inner">             
                  <h4 className="text-color-high">
                  VHL [ Dobbaspet ] 
                      </h4>
              </div>
              </div>
              <div className="tiles-item">
              <div className="tiles-item-inner">             
                  <h4 className="text-color-high">
                  Lotus Farms [Tumkur ]
                      </h4>
              </div>
              </div>
              <div className="tiles-item">
              <div className='tiles-item-padding'>
              <div className="tiles-item-inner">             
                  <h4 className="text-color-high">
                  Sujay Feeds <br/>[ Bangalore ]
                      </h4>
              </div>
              </div>
        </div>

        <div className="tiles-item">
              <div className='tiles-item-padding'>
              <div className="tiles-item-inner">             
                  <h4 className="text-color-high">
                  Higrovet Nutrition PVT LTD [ Nelamangala ] 
                      </h4>
              </div>
              </div>
        </div> */}

        

            {/* <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                <div className="features-tiles-item-image mb-16">
                <Image 
            src={require('./../../assets/images/godrejagro.jfif')}
            alt="Open"
            width={100}
            height={100} />
                  </div>
                
                  <p className="text-sm mb-0">
                    — Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum cillum dolore eu fugiat.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Diana Rynzhuk</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">AppName</a>
                  </span>
                </div>
              </div>
            </div> */}

           

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;