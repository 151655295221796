import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  pushLeft,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );


  const sectionHeader = {
    title: 'OUR EXPERTISE ',
    paragraph: 'Presently we are engaged with complete feed mill designing and manufacturing MASH feed plant and PLC Automated pellet feed plant.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item"style={ {padding: '30px',backgroundColor : '#d3d3d3'}}>
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12">
                  Pellet Mill
                  </h3>
                <p className="m-0">
                  A pellet mill, also known as a pellet press, is a type of mill or machine press used to create pellets from conditioned powdered material.                   </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/CONDITIONER_PELLET_MILL.png')}
                  alt="Features split 01"
                  width={200}
                  height={150} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" style={ {padding: '30px',backgroundColor : '#d3d3d3'}} data-reveal-container=".split-item">
                
                <h3 className="mt-0 mb-12">
                  Hammer Mill
                  </h3>
                <p className="m-0">
                Poultry feed hammer mill is widely used in small and medium sized animal feed processing factories for coarse grinding and fine grinding of raw materials.                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/HAMMERMILL.png')}
                  alt="Features split 02"
                  width={200}
                  height={150}  />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item" style={ {padding: '30px',backgroundColor : '#d3d3d3'}}> 
               
                <h3 className="mt-0 mb-12">
                 Mixer
                  </h3>
                <p className="m-0">
                We offer Mixer and Blender of indigenous design. Application like poultry feed and cattle feed, require liquids like molasses                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/MIXER.png')}
                  alt="Features split 03"
                  width={200}
                  height={150}  />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item" style={ {padding: '30px',backgroundColor : '#d3d3d3'}}> 
               
                <h3 className="mt-0 mb-12">
                 Conveyor
                  </h3>
                <p className="m-0">
                A conveyor belt is the carrying medium of a belt conveyor system (often shortened to belt conveyor). A belt conveyor system is one of many types of conveyor systems.      </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/Conveyor.png')}
                  alt="Features split 03"
                  width={200}
                  height={150}  />
              </div>
            </div>

          </div>
        </div>
      </div>

        <div>
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200"  style={ {backgroundColor : '#d3d3d3'}}>
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <h4>
                    Pellet Sieve 
                  </h4>
                  
                  <p className="text-sm mb-0">
                  Pellet Sieve is required for grading of pellets coming from pellet cooler.Pellets are graded and fed to the bin for bagging and the powder are fed to the pellet mill for re-pelleting.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                 
                
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                <h4>
                    Fabrication
                  </h4>
                  <p className="text-sm mb-0">
                  Fabrication is the process of constructing products by combining typically standardised parts using one or more individual processes. 
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  
                  
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200" style={ {backgroundColor : '#d3d3d3'}}>
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                <h4>
                    Bucket Elevators
                  </h4>
                  <p className="text-sm mb-0">
                  A bucket elevator, also called a grain leg, is a mechanism for hauling flowable bulk materials vertically.                    
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  
                </div>
              </div>
            </div>

          </div>
        </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;