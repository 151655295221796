import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import AboutUs from '../components/sections/AboutUs';

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      <Cta  >
      <div style={{textAlign:'center' }}>       
      We are <span className="testimonial-item-link">pioneer of contractual maintenance and operation service provider</span> of feed mill in India. 
      <br/> <br/> 
      We are the <span style={{fontSize:'50px' }} className="testimonial-item-link"> No 1 </span>
      company in this field
      </div>
      
      
      </Cta>
      <FeaturesSplit invertMobile topDivider imageFill />
      <Cta split >
      We provide in house <span className="testimonial-item-link">design , fabrication </span>   and <span className="testimonial-item-link"> setup of feed milling projects. </span>
      </Cta>
      <br/>
      <FeaturesTiles />
      <Testimonial topDivider />
      <AboutUs invertMobile topDivider imageFill />
    </>
  );
}

export default Home